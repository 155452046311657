import Vue from 'vue'
import App from './App.vue'
import {SSSA} from './components/sss-a';

import vueCustomElement from 'vue-custom-element'

import 'document-register-element/build/document-register-element'

import 'jquery/dist/jquery.min';
import 'popper.js/dist/umd/popper.min';
import 'bootstrap';

import './assets/plugins/font-awesome';

import 'sass-loader';

require('./assets/styles/main.scss');

customElements.define('sss-a', SSSA);

Vue.use(vueCustomElement)
Vue.config.ignoredElements = ['sss-li','sss-div', 'sss-a','sss-ul','sss-span']

Vue.customElement('vue-widget', App)
