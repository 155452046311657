import $ from "jquery";

export default {
  name: "App",
  props: ["wa", "tl", "fb", "button"],
  data: function () {
    return {
      buttonColor: "#129bf4",
      buttonText: "Ayuda",
      whatsappFullUrl: "",
      telegramFullUrl: "",
      messengerFullUrl: "",
      whatsappClient: "",
      telegramClient: "",
      messengerClient: "",
      whatsappText: "Hola, requiero más información",
      telegramText: "Hola, requiero más información",
      messengerText: "Hola, requiero más información",
      whatsappLabel: "Whatsapp",
      telegramLabel: "Telegram",
      messengerLabel: "Messenger",
      totalChannels: 0,
      totalParams: 3,
      active: false,
    };
  },
  created: function () {
    
    this.totalChannels = 0;

    this.whatsappFullUrl = "";
    this.telegramFullUrl = "";
    this.messengerFullUrl = "";

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    const whatsappBaseUrl = "https://wa.me/";
    const telegramBaseUrl = "https://t.me/";
    const messengerBaseUrl = "http://m.me/";

    if (this.wa) {
      this.processWhatsapp();
      this.totalChannels++;
    }

    if (this.tl) {
      this.processTelegram();
      this.totalChannels++;
    }

    if (this.fb) {
      this.processMessenger();
      this.totalChannels++;
    }

    if(this.button){
      this.processButton();
    }

    this.whatsappFullUrl =
      this.whatsappClient !== ""
        ? encodeURI(
            `${whatsappBaseUrl}${this.whatsappClient}?text=${this.whatsappText}`
          )
        : "";
    this.telegramFullUrl =
      this.telegramClient !== ""
        ? encodeURI(
            `${telegramBaseUrl}${this.telegramClient}?text=${this.telegramText}`
          )
        : "";
    this.messengerFullUrl =
      this.messengerClient !== ""
        ? encodeURI(
            `${messengerBaseUrl}${this.messengerClient}?text=${this.messengerText}`
          )
        : "";

    this.totalChannels > 1 ? (this.active = false) : (this.active = true);
  },
  methods: {
    processButton(){
      const splittedButton = this.button.trim().split(':');
      splittedButton[0] !== '' ? this.buttonColor = splittedButton[0] : null;
      splittedButton[1] !== '' ? this.buttonText = splittedButton[1] : null;
    },
    openLink(link){
      window.open(link);
    },
    toggle() {
      this.active = !this.active;
    },
    processWhatsapp() {
      const splittedWhatsapp = this.wa.trim().split(":");

      const verifiedString = this.verifyString(splittedWhatsapp);

      verifiedString[0] !== ""
        ? (this.whatsappClient = verifiedString[0])
        : null;
      verifiedString[1] !== "" ? (this.whatsappLabel = verifiedString[1]) : null;
      verifiedString[2] !== ""
        ? (this.whatsappText = verifiedString[2])
        : null;
    },
    processTelegram() {
      const splittedTelegram = this.tl.trim().split(":");

      const verifiedString = this.verifyString(splittedTelegram);

      verifiedString[0] !== ""
        ? (this.telegramClient = verifiedString[0])
        : null;
      verifiedString[1] !== "" ? (this.telegramLabel = verifiedString[1]) : null;
      verifiedString[2] !== ""
        ? (this.telegramText = verifiedString[2])
        : null;
    },
    processMessenger() {
      const splittedMessenger = this.fb.trim().split(":");

      const verifiedString = this.verifyString(splittedMessenger);

      verifiedString[0] !== ""
        ? (this.messengerClient = verifiedString[0])
        : null;
      verifiedString[1] !== ""
        ? (this.messengerLabel = verifiedString[1])
        : null;
      verifiedString[2] !== ""
        ? (this.messengerText = verifiedString[2])
        : null;
    },
    verifyString(splittedString) {
      if (splittedString.length > this.totalParams) {
        const formattedString = [];

        formattedString.push(splittedString[0]);
        formattedString.push(
          splittedString[1]
        );
        formattedString.push(splittedString.slice(2, splittedString.length).join(":"));

        return formattedString;
      } else {
        return splittedString;
      }
    },
  },
};